import React from 'react';
import { useTranslation } from 'react-i18next';

import dot1Svg from '../assets/svg/components/dots-1.svg';
import cloudHostingSvg from '../assets/svg/illustrations/cloud-hosting-amico.svg';
import codingSvg from '../assets/svg/illustrations/coding-amico.svg';
import webAppSvg from '../assets/svg/illustrations/javascript-frameworks-amico.svg';
import LocalizedLink from '../components/LocalizedLink';
import SEO from '../components/Seo';
import SocialProof from '../components/SocialProof/SocialProof';
import SVG from '../components/Svg';
import Technologies from '../components/Technologies';

const ServicesPage = () => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <SEO
        title={t('meta.services.title')}
        lang={i18n.language}
        description={t('meta.services.description')}
      />

      <section className="space-top-3 space-top-lg-2 space-bottom-2">
        <div className="gradient-y-gray position-relative">
          <div className="container position-relative z-index-2">
            <div className="row justify-content-lg-between align-items-md-center">
              <div className="col-md-6 col-lg-5 mb-5 mb-md-0">
                <div className="mb-4">
                  <h1 className="display-4">{t('navigation.services')}</h1>
                  <p>{t('services.subtitle')}</p>
                </div>

                <div>
                  <LocalizedLink to="/contact" className="btn btn-primary transition-3d-hover">
                    {t('label.getInTouch')}
                  </LocalizedLink>
                </div>
              </div>

              <Technologies />
            </div>
          </div>
          <figure>
            <svg
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 1920 100.1"
            >
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
            </svg>
          </figure>
        </div>
      </section>

      <section className="container space-2 space-lg-3">
        <div className="row justify-content-lg-between align-items-lg-center" id="web-development">
          <div className="col-lg-5 mb-5 mb-lg-0" data-aos="fade-right">
            <div className="mb-4">
              <h2>{t('services.1.title')}</h2>
              <p>{t('services.1.subtitle')}</p>
              <LocalizedLink to="/case-studies/roomex" className="btn btn-primary transition-3d-hover">
                Roomex {t('label.caseStudy')}
              </LocalizedLink>
            </div>
          </div>
          <figure className="col-lg-6 text-center" data-aos="fade-left">
            <img className="img-fluid w-lg-80" src={webAppSvg} alt="web app illustration" />
          </figure>
        </div>
      </section>

      <SVG />

      <section className="bg-light">
        <div className="container space-2 space-lg-3">
          <div
            className="row justify-content-lg-between align-items-lg-center"
            id="aws-serverless-development"
          >
            <div className="col-lg-5 order-lg-2 mb-5 mb-lg-0" data-aos="fade-left">
              <div className="mb-4">
                <h2>{t('services.2.title')}</h2>
                <p>{t('services.2.subtitle')}</p>
              </div>
            </div>
            <figure className="col-lg-6 order-lg-1 text-center" data-aos="fade-right">
              <img
                className="img-fluid w-lg-80"
                src={cloudHostingSvg}
                alt="cloud hosting illustration"
              />
            </figure>
          </div>
        </div>
      </section>

      <SVG type="ShapeBottom" />

      <section className="container space-2 space-lg-3">
        <div
          className="row justify-content-lg-between align-items-lg-center"
          id="saas-application-development"
        >
          <div className="col-lg-5 mb-5 mb-lg-0" data-aos="fade-right">
            <div className="mb-4">
              <h2>{t('services.3.title')}</h2>
              <p className="mb-4">{t('services.3.subtitle')}</p>
              <LocalizedLink to="/case-studies/schedulino" className="btn btn-primary transition-3d-hover">
                Schedulino {t('label.caseStudy')}
              </LocalizedLink>
            </div>
          </div>
          <figure className="col-lg-6 text-center" data-aos="fade-left">
            <img className="img-fluid w-lg-80" src={codingSvg} alt="coding illustration" />
          </figure>
        </div>
      </section>

      <section className="overflow-hidden">
        <div className="container space-2 space-lg-3">
          <div className="position-relative">
            <figure
              className="max-w-15rem w-100 position-absolute top-0 right-0"
              data-aos="fade-down"
            >
              <div className="mt-n5 mr-n5">
                <img className="img-fluid" src={dot1Svg} alt="dot" />
              </div>
            </figure>

            <div className="bg-light text-center rounded-lg p-4 p-md-5">
              <div className="w-md-80 w-lg-50 mx-md-auto">
                <h2>{t('label.buildProjectWithCodutech')}</h2>
                <p>{t('homePage.footer.subtitle')}</p>

                <LocalizedLink to="/contact" className="btn btn-primary transition-3d-hover mt-2">
                  {t('label.getInTouch')}
                </LocalizedLink>
              </div>
            </div>

            <figure
              className="max-w-15rem w-100 position-absolute bottom-0 left-0"
              data-aos="fade-up"
            >
              <div className="mb-n5 ml-n5">
                <img className="img-fluid" src={dot1Svg} alt="dot" />
              </div>
            </figure>
          </div>
        </div>
      </section>

      <section className="container space-top-1 space-bottom-2 space-bottom-lg-3">
        <SocialProof showAll />
      </section>
    </>
  );
};

export default ServicesPage;
